import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import AboutUsLocation from './AboutUsLocation'

// Page Banner
const PageBannerContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 5rem;
  background: ${`linear-gradient(315deg, rgba(204,255,192,0.32) 0%, #E5EDFF 100%)`};
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: auto;
    height: auto;
  }
`
const TitleHeader = styled.h1`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	align-items: center;
  font-size: 3em;
  text-align: center;
  font-weight: 700;
	line-height: 3.5rem;
	color: ${props => props.theme.colors.text};
`
const TitleDescriptionContainer = styled.div` 
	display: flex;
	width: 85vw;
	justify-content: center;
	align-items: center;
	text-align: center;
`
const TitleDescription = styled.h5`
	word-break: keep-all;
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: ${props => props.fontSize ? props.fontSize + `em` : `1em`};
  font-weight: 300;
	line-height: ${props => props.fontSize ? props.fontSize + .2 + `rem` : `1.2rem`};
  margin-top: ${props => props.marginTop ? props.marginTop + `rem` : `initial`};
	margin-left: ${props => props.marginLeft ? props.marginLeft + `rem` : `initial`};
	margin-right: ${props => props.marginRight ? props.marginRight + `rem` : `initial`};
	margin-bottom: ${props => props.marginBottom ? props.marginBottom + `rem` : `initial`};
`
const BoldfaceText = styled.span`
  font-weight: ${props => props.fontWeight ? props.fontWeight : `700`};
`
const InfoCardLarge = styled.div`
  display: flex;
	flex-direction: row;
  max-width: 60rem;
  min-height: 28rem;
  margin-top: 4rem;
  border: 1px solid ${props => props.theme.colors.green};
  background: ${props => props.theme.colors.background};
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
    max-width: 45rem;
  }
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		flex-wrap: wrap;
		max-width: 40rem;
		align-items: center;
		justify-content: center;
		margin-left: 2rem;
		margin-right: 2rem;
		padding-top: 2rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
    min-width: initial;
		margin-left: 0.75rem;
		margin-right: 0.75rem;
  }
`
const InfoCardContentCol = styled.div`
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
	width: ${props => props.ratio}%;
	min-width: 20rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		width: 50%;
  }
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 100%;
		min-width: initial;
  }
`
const InfoCardContentTextRow = styled.div`
  display: flex;
	padding: 0 4rem 0.5rem 1rem;
	flex-flow: column;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		padding: 1rem 2rem 0.5rem 1rem;
  }
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		padding: 3rem;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		padding: 1.5rem 1.5rem 2.5rem 1.5rem;
	}
`
const InfoCardContentTextTitle = styled.h4`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 1.25em;
	line-height: 1.8rem;
	padding-bottom: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		word-break: initial;
	}
`
const InfoCardContentTextSpan = styled.span`
	display: flex;
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 1em;
	line-height: 1.6rem;
	margin-top: 1.5rem;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		word-break: initial;
	}
`
const InfoCardContentImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
	padding-top: ${props => props.paddingTop}rem;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		height: 5rem;
	}
`
const StyledImg = styled(Img)`
  display: block;
  min-width: ${props => props.width}em;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		min-width: ${props => props.widthMobile}em;
	}
`
// Sections
const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding}rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: ${props => props.padding - 1}rem 0;
  }
`
const SectionContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
	max-width: 60rem;
  width: 100%;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 90%;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		width: 100%;
  }
`
const SectionContentCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: ${props => props.ratio}%;
	height: 100%;
	min-width: 20rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 100%;
		min-width: initial;
		max-width: 24rem;
		padding-top: 1rem;
	}
`
const SectionContentImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
	padding-top: ${props => props.paddingTop}rem;
`
const SectionContentTitle = styled.h2`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 2.25em;
	line-height: 3rem;
	padding: 1rem 0;
	text-align: center;
`
const SectionContentTitleResponsive = styled.h2`
	display: none;
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 2.25em;
	line-height: 2rem;
	padding: 1rem 0;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		display: flex;
		width: 100%;
		justify-content: center;
  }
`
const ResponsiveHide = styled.div`
	display: initial;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: none;
  }
`
const SectionContentTextSpan = styled.span`
	display: flex;
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 1.2em;
	line-height: 1.8rem;
	margin: 1.5rem 1rem 0;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
		text-align: center;
		margin: initial;
		padding: 1rem 1rem;
	}
`
const ProfileContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		text-align: center;
		margin-top: 1rem;
	}
`
const ProfileContentDesc = styled.div`
	display: flex;
	color: ${props => props.theme.colors[props.fontColor]};
	font-family: "Apple SD Gothic Neo";
	align-items: center;
	font-size: 1em;
	font-weight: 300;
	line-height: 1.2rem;
	margin-top: ${props => props.marginTop}rem;
	margin-left: ${props => props.marginLeft}rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-left: initial;
		justify-content: center;
		text-align: center;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		max-width: 20rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		width: 90%;
		margin-left: 1rem;
		margin-right: 1rem;
	}
`
const SectionDivider = styled.div`
	box-sizing: border-box;
	height: 1px;
	max-width: 60rem;
	background: ${props => props.theme.colors.lightGrey};
	border: 2px solid ${props => props.theme.colors.lightGrey};
	border-radius: 2px;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		width: 95%;
	}
`
const SectionContentRow = styled.div`
	display: flex;
	flex: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 20rem;
	padding-top: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
const StaffCardContainer = styled.div`
	// border: 1px dotted #ccc;
	display: flex;
	flex: 0 0 100%;
	flex-direction: column;
	min-height: 20rem;
	width: 100%;
	max-width: 29rem;
	padding: 1rem 0;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		padding: 1.25rem 0;
	}
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		display: ${props => props.hidden ? `none` : `flex`};
		align-items: center;
		justify-content: center;
	}
`
const StaffCardTitle = styled.h4`
	align-self: center;
	font-family: "Apple SD Gothic Neo";
	font-size: 1.25em;
	font-weight: 600;
	color: ${props => props.theme.colors.textGrey};
	line-height: 1.8rem;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		width: 90%;
		text-align: center;
	}
`
const StaffCardContent = styled.div`
	// border: 1px dotted #ccc;
	display: flex;
	flex-direction: row;
	padding: 1.5rem 0;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		flex-wrap: wrap;
		justify-content: center;
		padding: 1rem 0;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		max-width: 25rem;
	}
`
const StaffCardContentBio = styled.div`
	// border: 1px dotted #ccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		align-items: center;
		padding-top: 1rem;
		min-width: 20rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		min-width: initial;
	}
`
const ProfileContentName = styled.h5`
	word-break: keep-all;
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: ${props => props.fontSize ? props.fontSize + `em` : `1em`};
  font-weight: 300;
	line-height: ${props => props.fontSize ? props.fontSize + .2 + `rem` : `1.2rem`};
  margin-top: ${props => props.marginTop ? props.marginTop + `rem` : `initial`};
	margin-left: ${props => props.marginLeft ? props.marginLeft + `rem` : `initial`};
	margin-right: ${props => props.marginRight ? props.marginRight + `rem` : `initial`};
	margin-bottom: ${props => props.marginBottom ? props.marginBottom + `rem` : `initial`};
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		margin-left: initial;
	}
`
const StaffContactListTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 2rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
	}
`
const StaffContactListHeader = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 2rem;
	background: rgba(67,182,73,0.1);
`
const StaffContactListRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: 1px solid #AEB7BF;
`
const StaffContactListCell = styled.div`
	display: flex;
	flex: ${props => props.flex};
	width: 100%;
	min-height: ${props => props.minHeight ? props.minHeight : `5`}rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	word-break: keep-all;
	font-size: 1.2em;
	font-weight: ${props => props.fontWeight ? props.fontWeight : `initial`};
	font-family: "Apple SD Gothic Neo";
	padding: 1rem 0;
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	line-height: 1.6rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 0.9em;
  }
`
const MobileLineBreak = styled.br`
	&:after {
  }
	display: none;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`

const InfoImage = ({ filename, width,  widthMobile }) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					logo_southern_baptist_convention: file(relativePath: { eq: "logo_southern_baptist_convention.png" }) {
						childImageSharp {
							fluid(maxWidth: 900, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					logo_black: file(relativePath: { eq: "logo_black.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_pastor_lee: file(relativePath: { eq: "about_pastor_lee.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_amelie: file(relativePath: { eq: "about_amelie.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_em_pastor: file(relativePath: { eq: "about_em_pastor.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_may_park: file(relativePath: { eq: "about_may_park.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_image_coming_soon: file(relativePath: { eq: "about_image_coming_soon.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_pastor_im: file(relativePath: { eq: "about_pastor_im.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_an_jds: file(relativePath: { eq: "about_an_jds.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_pastor_kim: file(relativePath: { eq: "about_pastor_kim.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					about_sharlene_jds: file(relativePath: { eq: "about_sharlene_jds.png" }) {
						childImageSharp {
							fluid(maxWidth: 700, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			`}
			render={data => (
				<StyledImg
					className="InfoImage"
					fluid={data[`${filename}`].childImageSharp.fluid}
					width={width}
					widthMobile={widthMobile}
				/>
			)}
		/>
	)
}

const AboutUsPage = () => {
	return (
		<>
			<PageBannerContainer>
				<TitleContainer>
					<TitleHeader>
						{`JOYFUL CHURCH`} <MobileLineBreak />{` 소개`}
					</TitleHeader>
					<TitleDescriptionContainer>
						<TitleDescription marginTop={2.5} marginLeft={0.5} marginRight={0.5}>
							{`JOYFUL CHURCH의 정식 명칭은 `}<BoldfaceText>{`NORTHERN CALIFORNIA JOYFUL CHURCH`}</BoldfaceText>{` 입니다.`}
						</TitleDescription>
					</TitleDescriptionContainer>
				</TitleContainer>
				<InfoCardLarge>
					<InfoCardContentCol ratio={45}>
						<InfoCardContentImageRow paddingTop={1}>
							<InfoImage filename={"logo_black"} width={12} />
						</InfoCardContentImageRow>
						<InfoCardContentImageRow>
							<InfoImage filename={"logo_southern_baptist_convention"} width={17} />
						</InfoCardContentImageRow>
					</InfoCardContentCol>
					<InfoCardContentCol ratio={55}>
						<InfoCardContentTextRow>
							<InfoCardContentTextTitle>
								<BoldfaceText>
								{`
									Joyful Church는 개신교로는 세계적으로 가장 큰 교단인 미국 남침례 교단 (Southern Baptist)에 속해 있습니다.
								`}
								</BoldfaceText>
							</InfoCardContentTextTitle>
							<InfoCardContentTextSpan>
								{`
									미 남침례 교단은 복음적인 개신교단으로 천로 역정의 저자 요한 번연, 설교자 챨스 스펄전, 현대 선교의 아버지 윌리암 케리, 전도자 빌리 그레이엄, 인권 운동가 마틴 루터 킹, 존경받는 대통령 지미 카터 등을 배출한 교단입니다.
								`}
							</InfoCardContentTextSpan>
							<InfoCardContentTextSpan>
								{`
									오늘 날 전 세계적으로 주목을 받는 ‘새들백 교회’ (Saddleback Community Church)도 침례 교회에 속해 있습니다.
								`}
							</InfoCardContentTextSpan>
						</InfoCardContentTextRow>
					</InfoCardContentCol>
				</InfoCardLarge>
			</PageBannerContainer>
			<SectionContainer padding={5}>
				<SectionContentContainer>
					<SectionContentTitleResponsive>
						{`담임 목사 소개`}
					</SectionContentTitleResponsive>
					<SectionContentCol ratio={50}>
						<SectionContentImageRow>
							<InfoImage filename={"about_pastor_lee"} width={24} widthMobile={18}/>
						</SectionContentImageRow>
						<ProfileContainer>
							<TitleDescription marginTop={0.5} fontSize={1.2}><BoldfaceText fontWeight={600}>{`이상준 목사 (Sangjun Lee)`}</BoldfaceText></TitleDescription>
							<ProfileContentDesc marginTop={0.5}>{`Fuller Theological Seminary (Master of Divinity)`}</ProfileContentDesc>
							<ProfileContentDesc marginTop={0.5} fontColor={`green`}>
								<BoldfaceText fontWeight={600}>{`seniorpastor@joyfulchurch.us`}</BoldfaceText>
							</ProfileContentDesc>
						</ProfileContainer>
					</SectionContentCol>
					<SectionContentCol ratio={50}>
						<ResponsiveHide>
							<SectionContentTitle>
								{`담임 목사 소개`}
							</SectionContentTitle>
						</ResponsiveHide>
						<SectionContentTextSpan>
							{`
								이상준 목사는 2002년에 Fuller Theological Seminary에서 M. Div를 졸업하고 2011년 2월에 북가주 San Ramon에 올라와 가정에서 Joyful 교회를 시작하여, 2012년에 4월 15일에 창립 예배를 드렸습니다.
							`}
						</SectionContentTextSpan>
					</SectionContentCol>
				</SectionContentContainer>
			</SectionContainer>
			<SectionDivider />
			<SectionContainer padding={4}>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionContentTitle>
							<BoldfaceText fontWeight={600}>{`STAFF`}</BoldfaceText>{` 소개`}
						</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<StaffCardContainer>
							<StaffCardTitle>{`청년부 목사 / Pastor`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_pastor_kim"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`김태래 (Taerae Kim)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Golden Gate Baptist Theological Seminary (Master of Divinity)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`taerae2148@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`English Ministry Pastor`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_em_pastor"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`Da Yean Kim (김다연)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Tabot School of Theology (Master of Divinity)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`englishministry@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`목사 / Pastor`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_pastor_im"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`임두빈 (Doo Bin Im)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Talbot School of Theology (MA in Christian Education)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`idb0205@gmail.com`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`교육부 전도사 / Associate Pastor`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_an_jds"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`안소연 (So Yeun Ahn)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Talbot School of Theology (MA in Christian Education)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`soyeun.ahn@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`초등부 전도사 / Youth Pastor`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_sharlene_jds"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`Sharlene Kim`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Fuller Theological Seminary (MA)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`childrensministry@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`중고등부 (6-12학년) 전도사 / Youth (6-12th grade)`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_may_park"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`박메이 (May Park)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Fuller Theological Seminary (Master of Theology)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`may.park@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						<StaffCardContainer>
							<StaffCardTitle>{`행정 간사`}</StaffCardTitle>
							<StaffCardContent>
								<InfoImage filename={"about_amelie"} width={10} widthMobile={10} />
								<StaffCardContentBio>
									<ProfileContentName marginTop={0.5} marginLeft={1.5} fontSize={1.3}><BoldfaceText fontWeight={600}>{`변은아 (Amelie Byun)`}</BoldfaceText></ProfileContentName>
									<ProfileContentDesc marginTop={0.75} marginLeft={1.5}>{`Stanford University (Master of Arts)`}</ProfileContentDesc>
									<ProfileContentDesc marginTop={0.5} marginLeft={1.5} fontColor={`green`}>
										<BoldfaceText fontWeight={600}>{`amelie.byun@joyfulchurch.us`}</BoldfaceText>
									</ProfileContentDesc>
								</StaffCardContentBio>
							</StaffCardContent>
						</StaffCardContainer>
						{/* <StaffCardContainer hidden={true} /> */}
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
			<SectionDivider />
			<SectionContainer id={`contacts`} padding={5}>
				<SectionContentContainer>
					<SectionContentRow>
						<SectionContentTitle>{`각 부서 담당자 연락처`}</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<StaffContactListTable>
							<StaffContactListHeader>
								<StaffContactListCell flex={2} fontWeight={700} minHeight={3.5}>{`부서 명`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={700} minHeight={3.5}>{`담당자`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={700} minHeight={3.5}>{`이메일`}</StaffContactListCell>
							</StaffContactListHeader>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`소망부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`손민유 박세형`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`hope`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`유아/유치부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`김진아 선생님 `}<MobileLineBreak />{` 이혜진 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`preschool`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`초등부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`쉘린 김 전도사 `}<MobileLineBreak />{` 이혜진 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`childrensministry`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`중고등부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`메이 전도사`}<MobileLineBreak />{` 정병일 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`youth`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`카리스마 대학부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`이상준 목사`}<MobileLineBreak />{` 김연수 회장`}<MobileLineBreak />{` 김인웅 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`karisma`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`카이로스 청년부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`김태래 목사`}<MobileLineBreak />{` 현동구 회장`}<MobileLineBreak />{` 김인웅 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`kairos`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`English Ministry`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`Da Yean Kim`}<MobileLineBreak />{` (김다연 목사)`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`englishministry`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`목장 사역부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`차남식/차수경 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`cell`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`선교부`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`박세형 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`mission`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`중보기도 담당`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`김혜란 집사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`prayer`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`커피 브레이크`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`박미경 집사`}<MobileLineBreak />{` (San Ramon)`}<br/>{`심혜진 집사`}<MobileLineBreak />{` (East Bay)`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`coffeebreak`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`성경 통독`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`조혜영 전도사`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`heayoung.cho`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
							<StaffContactListRow>
								<StaffContactListCell flex={2} fontWeight={700}>{`행정 간사`}</StaffContactListCell>
								<StaffContactListCell flex={3}>{`변은아`}</StaffContactListCell>
								<StaffContactListCell flex={3} fontWeight={500} fontColor={`green`}>{`amelie.byun`}<MobileLineBreak />{`@joyfulchurch.us`}</StaffContactListCell>
							</StaffContactListRow>
						</StaffContactListTable>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
			<SectionContainer>
				<AboutUsLocation />
			</SectionContainer>
		</>
	)
}

export default AboutUsPage
